exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-w-chaqu-js": () => import("./../../../src/pages/w/chaqu.js" /* webpackChunkName: "component---src-pages-w-chaqu-js" */),
  "component---src-pages-w-fourtips-js": () => import("./../../../src/pages/w/fourtips.js" /* webpackChunkName: "component---src-pages-w-fourtips-js" */),
  "component---src-pages-w-mreviewer-js": () => import("./../../../src/pages/w/mreviewer.js" /* webpackChunkName: "component---src-pages-w-mreviewer-js" */),
  "component---src-pages-w-tdsc-js": () => import("./../../../src/pages/w/tdsc.js" /* webpackChunkName: "component---src-pages-w-tdsc-js" */),
  "component---src-pages-w-tenfax-js": () => import("./../../../src/pages/w/tenfax.js" /* webpackChunkName: "component---src-pages-w-tenfax-js" */),
  "component---src-pages-w-tfc-js": () => import("./../../../src/pages/w/tfc.js" /* webpackChunkName: "component---src-pages-w-tfc-js" */),
  "component---src-pages-w-usports-js": () => import("./../../../src/pages/w/usports.js" /* webpackChunkName: "component---src-pages-w-usports-js" */),
  "component---src-pages-w-vmp-3-js": () => import("./../../../src/pages/w/vmp3.js" /* webpackChunkName: "component---src-pages-w-vmp-3-js" */)
}

